import axios from "axios";
import constants from "../constants.json";
// import prod_constants from "../prod_constants.json";


const apiClient = axios.create({
  baseURL: process.env.VUE_APP_URL,
});
const headers = {
  headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
};

apiClient.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "userAccessToken"
    )}`;
    return config;
  },
  function (error) {
    console.log("Request Error:", error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    let errors = {};
    if (error.response) {
      if (error.response.status === 401) {
        errors = {
          auth_error: true,
        };
      }
      if (error.response.data.errors) {
        errors = error.response.data.errors;
      } else {
        errors.other_error = [error.response.data.message];
      }
    } else {
      errors.other_error = [error.message];
    }
    return Promise.reject(errors);
  }
);
export default {
  getInventory() {
    return apiClient.get(`${constants.MILES_WAREHOUSE}sku`, headers);
  },
  getCityInventory(category) {
    return apiClient.get(`${constants.MILES_WAREHOUSE}coursewiseCitySkus?category=${category}`, headers);
  },
  SubmitAddUnit(obj) {
    return apiClient.post(`${constants.MILES_WAREHOUSE}addUnits`, obj, headers);
  },
  generateCityWarehouseDispatch(obj) {
    return apiClient.post(`${constants.MILES_WAREHOUSE}generateCityWarehouseDispatch`,obj, headers);
  },
  SubmitReduceUnit(obj) {
    return apiClient.post(
      `${constants.MILES_WAREHOUSE}reduceUnits`,
      obj,
      headers
    );
  },
  addSkuName(obj) {
    return apiClient.post(`${constants.MILES_WAREHOUSE}sku`, obj, headers);
  },
  getSkuData() {
    return apiClient.get(`${constants.MILES_WAREHOUSE}sku`, headers);
  },
  updateSkuName(id, obj) {
    return apiClient.put(`${constants.MILES_WAREHOUSE}sku/${id}`, obj, headers);
  },
  deleteSkuName(id, obj) {
    return apiClient.delete(
      `${constants.MILES_WAREHOUSE}sku/${id}`,
      obj,
      headers
    );
  },
  getEnrollmentsRaw(obj) {
    return apiClient.post(`${constants.SERVER_API}getEnrollmentsRaw`, obj, headers);
  },
  getEnrollmentsSummary(obj) {
    return apiClient.post(`${constants.SERVER_API}getEnrollmentsSummary`, obj, headers);
  },
  storeRegistrant(obj) {
    return apiClient.post(`${constants.MILES_WEBINARS}storeRegistrant`, obj, headers);
  },
  registerForAcceleratorSession(obj) {
    return apiClient.post(`${constants.SERVER_API}registerForAcceleratorSession`, obj, headers);
  },
  getUnauthorizedContacts(page) {
    return apiClient.get(`${constants.MILES_GOOGLE_INVITE}getUnauthorizedContacts?page=${page}`, headers);
  },
  getWhatsAppSentData(page) {
    return apiClient.get(`${constants.MILES_GOOGLE_INVITE}getWhatsAppSentData?page=${page}`, headers);
  },
  //CM Dashboard
  getNetEnquiryData(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallNetEnquiry?start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
  },
  getEnrollmentData(startDate, endDate, obj) {
    let objj = {
        'gm':obj.gm,
        'spoc':obj.spoc,
        'team':obj.team,
        'city':obj.city,
        'start_date':startDate,
        'end_date':endDate
    }
    if (localStorage.getItem('team') == 'Admin') {
      return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallEnrollmentsCount`,{params:objj}, headers);
    }else{
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallEnrollmentsCount`,{params:objj}, headers);

    }
  },
  getCityEnrollment(startDate, endDate, obj) {
    if (localStorage.getItem('team') == 'Admin') {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getCitySummaryCount?team=${obj.team}&start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}&course=${obj.course}`, headers);
    }else{
    return apiClient.get(`${constants.MILES_DASHBOARDS}getCitySummaryCount?start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}&course=${obj.course}`, headers);
    }
  },
  getNetEnquirysCitySummary(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getNetEnquiryCitySummary?end_date=${endDate}&city=${obj.city}&gm=${obj.gm}&source=${obj.source}&spoc=${obj.spoc}&start_date=${startDate}`, headers);
  },
  getLeadsCitySummary(obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getLeadsCitySummary?spoc=${obj.spoc}&gm=${obj.gm}&level=${obj.source}`, headers);
  },
  getNetEnquirySpocSummary(obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getNetEnquirySpocSummary?start_date=${obj.startDate}&end_date=${obj.endDate}&source=${obj.source}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
  },
  getSpocSummary(enrollmentSpoc) {
    if (localStorage.getItem('team') == 'Admin') {
        return apiClient.get(`${constants.MILES_DASHBOARDS}getSpocSummary?team=${enrollmentSpoc.team}&start_date=${enrollmentSpoc.startDate}&end_date=${enrollmentSpoc.endDate}&city=${enrollmentSpoc.city}&course=${enrollmentSpoc.course}&spoc=${enrollmentSpoc.spoc}&gm=${enrollmentSpoc.gm}`, headers);
      }else{
        return apiClient.get(`${constants.MILES_DASHBOARDS}getSpocSummary?start_date=${enrollmentSpoc.startDate}&end_date=${enrollmentSpoc.endDate}&city=${enrollmentSpoc.city}&course=${enrollmentSpoc.course}&spoc=${enrollmentSpoc.spoc}&gm=${enrollmentSpoc.gm}`, headers);
      }
  },
  getVisitsData(startDate, endDate, obj) {
    let objj = {
      'gm':obj.gm,
      'spoc':obj.spoc,
      'team':obj.team,
      'city':obj.city,
      'start_date':startDate,
      'end_date':endDate
  }
    if (localStorage.getItem('team') == 'Admin') {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallVisitsCalls`,{params:objj}, headers);
    }else{
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallVisitsCalls`,{params:objj}, headers);
    }
  },
  getCallsCitySummary(startDate, endDate, obj) {
    if (localStorage.getItem('team') == 'Admin') {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getCallsCitySummary?team=${obj.team}&start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
        }else{
    return apiClient.get(`${constants.MILES_DASHBOARDS}getCallsCitySummary?start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
        }
  },
  getVisitsCitySummary(startDate, endDate, obj) {
    if (localStorage.getItem('team') == 'Admin') {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getVisitsCitySummary?team=${obj.team}&start_date=${startDate}&end_date=${endDate}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
      }else{
    return apiClient.get(`${constants.MILES_DASHBOARDS}getVisitsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
      }
  },
  getCallsSpocSummary(callsSpoc) {
    if (localStorage.getItem('team') == 'Admin') {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getCallsSpocSummary?team=${callsSpoc.team}&start_date=${callsSpoc.startDate}&end_date=${callsSpoc.endDate}&city=${callsSpoc.city}&course=${callsSpoc.course}&spoc=${callsSpoc.spoc}&gm=${callsSpoc.gm}`, headers);
        }else{
    return apiClient.get(`${constants.MILES_DASHBOARDS}getCallsSpocSummary?start_date=${callsSpoc.startDate}&end_date=${callsSpoc.endDate}&city=${callsSpoc.city}&course=${callsSpoc.course}&spoc=${callsSpoc.spoc}&gm=${callsSpoc.gm}`, headers);
        }
  },
  getVisitSpocSummary(visitSpoc) {
    if (localStorage.getItem('team') == 'Admin') {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getVisitsSpocSummary?team=${visitSpoc.team}&start_date=${visitSpoc.startDate}&end_date=${visitSpoc.endDate}&city=${visitSpoc.city}&course=${visitSpoc.course}&spoc=${visitSpoc.spoc}&gm=${visitSpoc.gm}`, headers);
        }else{
    return apiClient.get(`${constants.MILES_DASHBOARDS}getVisitsSpocSummary?start_date=${visitSpoc.startDate}&end_date=${visitSpoc.endDate}&city=${visitSpoc.city}&course=${visitSpoc.course}&spoc=${visitSpoc.spoc}&gm=${visitSpoc.gm}`, headers);
        }
  },
  getEligibilityData(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallEligibilitySummary?start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
  },
  getEligibilityCitySummary(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getEligibilityCitySummary?start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
  },
  getEligibilitySpocSummary(eligibilitySpoc) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getEligibilitySpocSummary?start_date=${eligibilitySpoc.startDate}&end_date=${eligibilitySpoc.endDate}&city=${eligibilitySpoc.city}&course=${eligibilitySpoc.course}&spoc=${eligibilitySpoc.spoc}&gm=${eligibilitySpoc.gm}`, headers);
  },
  getReferralsData(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallReferralSummary?start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
  },
  getReferralsCitySummary(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getReferralCitySummary?start_date=${startDate}&end_date=${endDate}&city=${obj.city}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
  },
  getReferralsSpocSummary(referralSpoc) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getReferralSpocSummary?start_date=${referralSpoc.startDate}&end_date=${referralSpoc.endDate}&city=${referralSpoc.city}&course=${referralSpoc.course}&spoc=${referralSpoc.spoc}&gm=${referralSpoc.gm}`, headers);
  },
  getOverallTrendsSummary(gm, spoc,team,city) {
    let obj={
      'gm':gm,
      'spoc':spoc,
      'team':team,
      'city':city
    }
    if (localStorage.getItem('team') == 'Admin') {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallTrendSummary`, {params:obj},headers);
    }else{
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallTrendSummary`, {params:obj}, headers);
    }
  },
  getAllIndiaRankSummary(startDate, endDate, spoc) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getSpocAllIndiaRank?end_date=${endDate}&spoc=${spoc}&start_date=${startDate}`, headers);
  },
  getStudentConnectDetails(level, type_of_connect, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getStudentConnectDetails?level=${encodeURIComponent(level)}&type=${type_of_connect}`, { params: obj });
  },
  evaluationDetails(evaluation_type, page, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}evaluationDetails?evaluation_type=${evaluation_type}&page=${page}`, { params: obj });
  },
  timeperiodEvaluationDetails(time_period_type, startDate, endDate, page, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}timeperiodEvaluationDetails?evaluation_type=${time_period_type}&from_date=${startDate}&to_date=${endDate}&page=${page}`, { params: obj });
  },
  eligibilityCount(obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}eligibilityCount`, { params: obj });
  },
  eligibilityDetails(eligibility_type, page, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}eligibilityDetails?eligibility_type=${eligibility_type}&page=${page}`, { params: obj });
  },
  timeperiodEligibility(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}timeperiodEligibility?from_date=${startDate}&to_date=${endDate}`, { params: obj });
  },
  timeperiodEligibilityDetails(type, startDate, endDate, page, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}timeperiodEligibilityDetails?eligibility_type=${type}&from_date=${startDate}&${endDate}&page=${page}`, { params: obj });
  },
  getOverallLeads() {
    return apiClient.get(`${constants.MILES_CM_BACK}getPresignedUrlForSpocDashboardData?file_name=adminSpocDashboardCm.json`);
  },
  getOverallLeadsCard(obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getOverallLeads?spoc=${obj.spoc}&gm=${obj.gm}`, headers);

  },
  getLeadsSpocSummary(obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getLeadsSpocSummary?city=${obj.city}&level=${obj.level}&spoc=${obj.spoc}&gm=${obj.gm}`, headers);
  },

  getCPAStudyPlannerDetails(mwb_id) {
    return apiClient.get(`${constants.MILES_SR}getCPADashboardApi?mwb_id=${mwb_id}`, headers);
  },
  getCMAStudyPlannerDetails(mwb_id) {
    return apiClient.get(`${constants.MILES_SR}getCMADashboardApi?mwb_id=${mwb_id}`, headers);
  },
  getWebinarDetails() {
    return apiClient.get(`${constants.MILES_SR}milesWebinarsList`, headers);
  },
  getOtbAttempts(mwb_id) {
    return apiClient.get(`${constants.MILES_SR}otbAttempt?mwb_id=${mwb_id}`, headers);
  },
  getExamDetails(mwb_id) {
    return apiClient.get(`${constants.MILES_SR}otbExamDate?mwb_id=${mwb_id}`, headers);
  },
  getAttendance(course, mwb_id) {
    return apiClient.get(`${constants.MILES_SR}connectToLMS?course=${course}&mwb_id=${mwb_id}`, headers);
  },
  storeMeetAndGreet(candidate_details) {
    return apiClient.post(`${constants.MILES_GOOGLE_INVITE}bookHrSlot`, candidate_details, headers);
  },
  // bookedSlotsForHr(name, date) {
  //   return apiClient.get(`${constants.MILES_GOOGLE_INVITE}bookedSlotsForHr?hr_name=${name}&date=${date}`, headers);
  // },
  getTopSpocs(startDate,endDate,team,city){
    if (localStorage.getItem('team') == 'Admin') {
     return apiClient.get(`${constants.MILES_DASHBOARDS}getTopSpocsSummary?city=${city}&team=${team}&start_date=${startDate}&end_date=${endDate}`, headers);
    }else{
     return apiClient.get(`${constants.MILES_DASHBOARDS}getTopSpocsSummary?city=${city}&start_date=${startDate}&end_date=${endDate}`, headers);
    }
  },
  getRoadmapOverallCounts(startDate, endDate, city, spoc, gm) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getRoadmapOverallCounts?start_date=${startDate}&end_date=${endDate}&city=${city}&spoc=${spoc}&gm=${gm}`, headers)
  },
  getRoadmapCitySummary(startDate, endDate, city, spoc, gm) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getRoadmapCitySummary?start_date=${startDate}&end_date=${endDate}&city=${city}&spoc=${spoc}&gm=${gm}`, headers)
  },
  getRoadmapSpocSummary(data, city,) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}getRoadmapSpocSummary?start_date=${data.startDate}&end_date=${data.endDate}&city=${city}&spoc=${data.spoc}&gm=${data.gm}`, headers)
  },

  // SR Spoc Dashboard
  spocEvaluationCount(obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}spocEvaluationCount`, { params: obj });
  },
  timeperiodspocEvaluationCount(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}timeperiodspocEvaluationCount?from_date=${startDate}&to_date=${endDate}`, { params: obj });
  },
  spocEvaluationDetails(type, page, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}spocEvaluationDetails?evaluation_type=${type}&page=${page}`, { params: obj });
  },
  timeperiodspocEvaluationDetails(type, startDate, endDate, page, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}timeperiodspocEvaluationDetails?evaluation_type=${type}&from_date=${startDate}&to_date=${endDate}&page=${page}`, { params: obj });
  },
  spocStudentConnectDetails(level, type_of_connect, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}spocStudentConnectDetails?level=${encodeURIComponent(level)}&type=${type_of_connect}`, { params: obj });
  },
  spocEligibilityCount(obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}spocEligibilityCount`, { params: obj });
  },
  spocEligibilityDetails(type, page, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}spocEligibilityDetails?eligibility_type=${type}&page=${page}`, { params: obj });
  },
  timeperiodSpocEligibilityCount(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}timeperiodSpocEligibilityCount?from_date=${startDate}&to_date=${endDate}`, { params: obj });
  },
  timeperiodSpocEligibilityDetails(type, startDate, endDate, page, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}timeperiodSpocEligibilityDetails?eligibility_type=${type}&from_date=${startDate}&to_date=${endDate}&page=${page}`, { params: obj });
  },


  // SR Dashboard/Student Connect
  getStudentConnectStats(obj) {
    return apiClient.get(`http://13.233.15.53/api/getStudentConnectStats`, { params: obj });
  },
  evaluationCount(obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}evaluationCount`, { params: obj });
  },
  timeperiodEvaluationCount(startDate, endDate, obj) {
    return apiClient.get(`${constants.MILES_DASHBOARDS}timeperiodEvaluationCount?from_date=${startDate}&to_date=${endDate}`, { params: obj });
  },
  getSpocCity() {
    return apiClient.get(`${constants.MILES_GOOGLE_INVITE}getSpocCity`, headers);
  },
  storeNextFollowUps(details) {
    return apiClient.post(`${constants.MILES_GOOGLE_INVITE}storeNextFollowUps`, details, headers);
  },
  srIncentiveSummary(obj){
    return apiClient.get(`${constants.MILES_SR}srIncentiveSummary?start_date=${obj.start_date}&end_date=${obj.end_date}&spoc_id=${obj.spoc_id}`, headers)
  },
  srIncentiveRaw(obj){
    return apiClient.get(`${constants.MILES_SR}srIncentiveRaw?start_date=${obj.start_date}&end_date=${obj.end_date}&spoc_id=${obj.spoc_id}&days_classification=${obj.days_classification}&category=${obj.category}`, headers)
  },
  maccCmLevelSummary(obj){
    return apiClient.get(`${constants.MILES_GOOGLE_INVITE}maccCmLevelSummary`,{params:obj, headers})
  },
maccCmLevelRaw(cm_level, macc_level, current_table_page, total_count,start_date,end_date,spoc_id,enrollment_from_date,enrollment_to_date,enrollment_month,enrollment_year, team){
  if(team == 'SR'){
    return apiClient.get(`${constants.MILES_GOOGLE_INVITE}maccCmLevelRaw?cm_level=${cm_level}&macc_level=${macc_level}&page_number=${current_table_page}&total_count=${total_count}&eligibility_from_date=${start_date}&eligibility_to_date=${end_date}&sr_id=${spoc_id}&enrollment_from_date=${enrollment_from_date}&enrollment_to_date=${enrollment_to_date}&enrollment_month=${enrollment_month}&enrollment_year=${enrollment_year}&team=${team}`, headers)
  }else {
    return apiClient.get(`${constants.MILES_GOOGLE_INVITE}maccCmLevelRaw?cm_level=${cm_level}&macc_level=${macc_level}&page_number=${current_table_page}&total_count=${total_count}&eligibility_from_date=${start_date}&eligibility_to_date=${end_date}&spoc_id=${spoc_id}&enrollment_from_date=${enrollment_from_date}&enrollment_to_date=${enrollment_to_date}&enrollment_month=${enrollment_month}&enrollment_year=${enrollment_year}&team=${team}`, headers)
  }
  },
  // bookedSlotsForHrMacc(name,date){
  //   return apiClient.get(`${constants.MILES_GOOGLE_INVITE}getMeetAndGreetMacc?hr_name=${name}&date=${date}`, headers);
  // },
  maccBookHrSlot(candidate_details){
    return apiClient.post(`${constants.MILES_GOOGLE_INVITE}maccBookHrSlot`,candidate_details, headers);
  },
};
