<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="15">
        <vs-card class="cardx">
          <div>
            <vs-table pagination search :data="inventoryData" max-items="10">
              <template slot="header">
                <vs-row class="gap" style="bottom: 10px; position: relative">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <h3>Inventory</h3>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"></vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-w="5">
                    <vs-select autocomplete label="Categories" v-model="seleted_category">
                      <vs-select-item :key="index" :value="item.text" :text="item.text"
                        v-for="(item, index) in categories_option" />
                    </vs-select>
                    <vs-button color="#6200ee" icon="search" style="margin: 19px 0 0 5px; "
                      @click="courseFilter(seleted_category)" :disabled="!seleted_category.length"></vs-button>
                    <vs-button color="#6200ee" style="margin: 19px 0 0 5px" @click="clearSelectCategory" icon="close"
                      :disabled="!seleted_category.length">
                    </vs-button>
                  </vs-col>
                  <!-- :disabled="!courses.length"  -->
                </vs-row>
              </template>
              <template slot="thead">
                <vs-th> SKU </vs-th>
                <vs-th> Category </vs-th>
                <vs-th> Quantity </vs-th>
                <vs-th> Weight </vs-th>
                <!-- <vs-th> Addition </vs-th> -->

              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].name">
                    {{ data[indextr].name }}
                  </vs-td>
                  <vs-td :data="data[indextr].category">
                    {{ data[indextr].category }}
                  </vs-td>
                  <vs-td :data="data[indextr].quantity_in_stock">
                    {{ data[indextr].quantity_in_stock }}
                  </vs-td>
                  <vs-td :data="data[indextr].weight">
                    {{ data[indextr].weight }}
                  </vs-td>

                  <!-- <vs-td :data="data[indextr].addition">
                    <vs-row>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center">
                        <vs-button radius color="success" type="gradient" icon="add" size="small"
                          @click="addUnit(data[indextr])"></vs-button>&nbsp;
                        <vs-button radius color="dark" type="gradient" icon="remove" size="small"
                          @click="reduceUnit(data[indextr])"></vs-button>
                      </vs-col>
                    </vs-row>
                  </vs-td> -->

                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-popup class="unitPopup" title="Add Unit" :active.sync="addUnitPopup">
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-center" vs-align="center" vs-w="12">
          <label><b>{{ selectedSkuName }}</b></label>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-center" vs-align="center" vs-w="12">
          <vs-input type="number" class="inputx" v-model="UnitAdditionNumber" />
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button type="gradient" color="success" @click="SubmitAddUnit()">Update</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup class="unitPopup" title="Reduce Unit" :active.sync="reduseUnitPopup">
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-center" vs-align="center" vs-w="12">
          <label><b>{{ selectedSkuName }}</b></label>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-center" vs-align="center" vs-w="12">
          <vs-input type="number" class="inputx" v-model="UnitReduceNumber" />
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button type="gradient" color="success" @click="SubmitReduceUnit()">Update</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
// import axios from "axios";
// import constants from "../../../constants.json";
import ApiServices from "../../ApiServices";
export default {
  components: {
  },
  data() {
    return {
      inventoryData: [],
      addUnitPopup: false,
      reduseUnitPopup: false,
      UnitAdditionNumber: "",
      UnitReduceNumber: "",
      selectedSkuName: "",
      seleted_category: "",
      categories_option: [{ text: 'CPA' },
      { text: 'CMA' },
      { text: 'CFA' },
      { text: 'FRM' },
      { text: 'Digital Marketing' },
      { text: 'SHRM' },
      { text: 'Wiley Black' },
      { text: 'Product Management' },
      { text: 'AI/ML SQL' },
      { text: 'HBR' },
      { text: 'KPMG' },
      { text: 'Misc' },
      { text: 'MAC'},
      { text: 'WCBA'}],
      filter_data: [],
      findData: [],
    }
  },
  mounted() {
    this.getInventory();

  },
  methods: {
    getInventory() {
      this.$vs.loading();
      ApiServices.getInventory()
        .then((response) => {
          console.log(response);
          this.inventoryData = response.data.data;
          this.filter_data = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
          this.$vs.loading.close();
        })
    },
    addUnit(trData) {
      console.log("tabledata", trData);
      this.selectedSkuName = trData.name;
      this.selectedUnitId = trData.id;
      this.addUnitPopup = true;
    },
    reduceUnit(trData) {
      console.log("tabledata", trData);
      this.selectedSkuName = trData.name;
      this.selectedUnitId = trData.id;
      this.reduseUnitPopup = true;
    },
    SubmitAddUnit() {
      let obj = {
        sku_id: this.selectedUnitId,
        quantity: parseInt(this.UnitAdditionNumber),
      };
      ApiServices.SubmitAddUnit(obj)
        .then((response) => {
          console.log("responce", response);
          this.addUnitPopup = false;
          this.$vs.notify({
            title: "Updated",
            text: "",
            color: "success",
          });
          this.UnitAdditionNumber = "";
          this.getInventory();
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
        });
    },
    SubmitReduceUnit() {
      let obj = {
        sku_id: this.selectedUnitId,
        quantity: parseInt(this.UnitReduceNumber),
      };
      ApiServices.SubmitReduceUnit(obj)
        .then((response) => {
          console.log("responce", response);
          this.reduseUnitPopup = false;
          this.$vs.notify({
            title: "Updated",
            text: "",
            color: "success",
          });
          this.UnitReduceNumber = "";
          this.getInventory();
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
        });
    },
    courseFilter(category) {
      // console.log(category)
      this.inventoryData = [];
      let inventory_data = this.filter_data
      for (let i = 0; i < inventory_data.length; i++) {
        const element = inventory_data[i];
        if (category == element.category) {
          this.inventoryData.push(element);
        }
      }
      // console.log("findData",this.findData)
    },
    clearSelectCategory() {
      this.seleted_category = ""
      this.inventoryData = [];
      this.getInventory();
    }
  }

}
</script>

<style>
</style>
