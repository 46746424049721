<template>
  <div>
      <new-inventory></new-inventory>
  </div>
</template>

<script>
import NewInventory from "../components/inventory/NewInventory.vue"
export default {
    components:{
        NewInventory
    }
};
</script>

<style>
</style>